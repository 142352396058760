import { createAction, props } from "@ngrx/store";
import {} from "@cg/core/types";
import type { BookAppointmentRequest } from "../interfaces/book-appointment-request.interface";
import type { GetAppointmentsRequest } from "../interfaces/get-appointments-request.interface";
import type { GetAppointmentsResponse } from "../interfaces/get-appointments-response.interface";
import type { GetServiceCenterRequest } from "../interfaces/get-service-center-request.interface";
import type { ServiceCenterLocation } from "@cg/core/interfaces";

export const getScs = createAction(
  "[Protect Booking] Get Service Center",
  props<{ payload: GetServiceCenterRequest }>()
);
export const getScsSuccess = createAction(
  "[Protect Booking] Get Service Center Success",
  props<{ payload: ServiceCenterLocation[] }>()
);
export const getScsFailure = createAction("[Protect Booking] Get Service Center Failure", props<{ payload: string }>());

export const getAppointments = createAction(
  "[Protect Booking] Get Appointments",
  props<{ payload: GetAppointmentsRequest }>()
);
export const getAppointmentsSuccess = createAction(
  "[Protect Booking] Get Appointments Success",
  props<{ payload: GetAppointmentsResponse[] }>()
);
export const getAppointmentsFailure = createAction(
  "[Protect Booking] Get Appointments Failure",
  props<{ payload: string }>()
);

export const book = createAction("[Protect Booking] Book", props<{ payload: BookAppointmentRequest }>());
export const bookSuccess = createAction("[Protect Booking] Book Success");
export const bookFailure = createAction("[Protect Booking] Book Failure", props<{ payload: string }>());
