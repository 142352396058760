<cg-dialog
  class="text-cg-anthracite [&>div>div>a]:top-5 [&>div>div]:p-0 [&>div]:x-[p-6,pb-4] t:[&>div]:x-[m-0,max-w-xs,pb-6] d:[&>div]:x-[max-w-[400px],p-10]"
>
  <div class="mb-6 flex flex-col items-center gap-2 text-center">
    <cg-icon class="mb-1 h-12 w-12" [content]="warningIcon"></cg-icon>
    <cg-headline [content]="'protectBooking.errorOverlay.title' | transloco" [type]="headlineType.h2"></cg-headline>
    <cg-paragraph
      [content]="{ text: 'protectBooking.errorOverlay.subTitle' | transloco, ngTemplate: 'cgParagraph' }"
      class="part-[text|>br]:block part-[text]:x-[text-[13px],leading-5]"
    >
    </cg-paragraph>
  </div>
  <button
    [btnText]="'protectBooking.errorOverlay.cta' | transloco"
    (click)="close()"
    class="w-full"
    cg-base-button
  ></button>
</cg-dialog>
