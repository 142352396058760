import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { ServiceCenterLocation } from "@cg/core/interfaces";
import { UnifiedError } from "@cg/core/types";
import { errorToString } from "@cg/core/utils";
import { OverlayService } from "@cg/shared";
import { BookAppointmentRequest } from "../interfaces/book-appointment-request.interface";
import { GetAppointmentsRequest } from "../interfaces/get-appointments-request.interface";
import { GetAppointmentsResponse } from "../interfaces/get-appointments-response.interface";
import { GetServiceCenterRequest } from "../interfaces/get-service-center-request.interface";
import { ProtectBookingErrorComponent } from "../protect-booking-error/protect-booking-error.component";
import { ProtectBookingService } from "../services/protect-booking.service";
import * as ProtectBookingActions from "./protect-booking.actions";

@Injectable()
export class ProtectBookingEffects {
  private readonly actions$ = inject(Actions);
  private readonly protectBookingService = inject(ProtectBookingService);
  private readonly router = inject(Router);
  private readonly overlayService = inject(OverlayService);

  public getServiceCenters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtectBookingActions.getScs),
      map(({ payload }: { payload: GetServiceCenterRequest }) => payload),
      switchMap((payload: GetServiceCenterRequest) =>
        this.protectBookingService.getScs(payload).pipe(
          take(1),
          switchMap((serviceCenters: ServiceCenterLocation[]) =>
            of(ProtectBookingActions.getScsSuccess({ payload: serviceCenters }))
          ),
          catchError((e: UnifiedError) => of(ProtectBookingActions.getScsFailure({ payload: errorToString(e) })))
        )
      )
    )
  );

  public getAppointments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtectBookingActions.getAppointments),
      map(({ payload }: { payload: GetAppointmentsRequest }) => payload),
      switchMap((payload: GetAppointmentsRequest) =>
        this.protectBookingService.getAppointments(payload).pipe(
          take(1),
          switchMap((appointments: GetAppointmentsResponse[]) =>
            of(ProtectBookingActions.getAppointmentsSuccess({ payload: appointments }))
          ),
          catchError((e: UnifiedError) =>
            of(ProtectBookingActions.getAppointmentsFailure({ payload: errorToString(e) }))
          )
        )
      )
    )
  );

  public book$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtectBookingActions.book),
      map(({ payload }: { payload: BookAppointmentRequest }) => payload),
      switchMap((payload: BookAppointmentRequest) =>
        this.protectBookingService.book(payload).pipe(
          take(1),
          switchMap(() => of(ProtectBookingActions.bookSuccess())),
          catchError((e: UnifiedError) => of(ProtectBookingActions.bookFailure({ payload: errorToString(e) })))
        )
      )
    )
  );

  public bookSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtectBookingActions.bookSuccess),
        tap(() => {
          this.router.navigateByUrl("/protect-bestaetigung");
        })
      ),
    { dispatch: false }
  );

  public bookFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtectBookingActions.bookFailure),
        tap(() => {
          this.overlayService.open(ProtectBookingErrorComponent, null, {
            positionByBreakpoints: OverlayService.POSITION_M_CENTER
          });
        })
      ),
    { dispatch: false }
  );
}
