import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { TranslocoPipe } from "@jsverse/transloco";
import { BadgeComponent, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { BaseButtonComponent } from "@cg/shared";
import type { Cockpit } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-cockpit",
  templateUrl: "./cockpit.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [BadgeComponent, IconComponent, ParagraphComponent, BaseButtonComponent, TranslocoPipe]
})
export class CockpitComponent {
  @Input()
  public content!: Cockpit;

  @Input()
  public translate = false;

  public constructor(private readonly router: Router) {}

  public openLink(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    if (this.isExternal) {
      window.open(new URL(this.content.cta.link.href), this.content.cta.link?.target ?? "_self");
      return;
    }

    this.router.navigate([this.content.cta.link.href]);
  }

  private get isExternal(): boolean {
    if (this.content && this.content.cta.link && this.content.cta.link.href) {
      return this.content.cta.link.href.includes("//") || this.content.cta.link.href.includes(":");
    }

    return false;
  }
}
