<div class="mx-auto mt-6 flex max-w-container flex-col t:mt-8 t:px-2 w:px-6 fhd:px-0">
  <form class="max-w-content" [formGroup]="form">
    <cg-headline
      type="h2"
      [content]="'protectBooking.headlines.title' | transloco: { price: price() }"
      class="mb-8 block part-[headline]:leading-9 t:mb-12"
    >
    </cg-headline>

    <div class="mb-4 t:mb-8">
      <cg-headline
        class="mb-4 block"
        type="h3"
        [content]="'protectBooking.headlines.contact' | transloco"
      ></cg-headline>

      <div class="grid grid-cols-1 gap-x-4 gap-y-2 t:grid-cols-2">
        <cg-text-input
          class="w-full"
          [content]="inputFields.firstNameInput"
          formControlName="firstName"
          data-cy="firstname"
        ></cg-text-input>
        <cg-text-input
          class="w-full"
          [content]="inputFields.lastNameInput"
          formControlName="lastName"
          data-cy="lastname"
        ></cg-text-input>
        <cg-text-input
          class="w-full"
          [content]="inputFields.emailInput"
          [specificInputType]="InputType.E_MAIL"
          formControlName="email"
          data-cy="email"
        ></cg-text-input>
      </div>
    </div>

    <div class="mb-4 t:mb-8">
      <cg-headline class="mb-4 block" type="h3" [content]="'protectBooking.headlines.place' | transloco"></cg-headline>
      <div class="flex flex-col gap-2 t:flex-row t:gap-4">
        <cg-google-places-input
          class="appointment--search-input h-fit w-full self-end t:part-[input]:!bg-transparent"
          [icon]="null"
          [doneIcon]="doneIcon"
          [content]="inputFields.placeInput"
          (autocompleteResult)="handleAutocompleteResult($event)"
        ></cg-google-places-input>

        <div class="flex w-full flex-col">
          @if (scOptions().length > 0) {
            <span
              class="font-roboto-condensed text-[13px] text-cg-anthracite"
              [innerHtml]="'protectBooking.scCount' | transloco: { count: scOptions().length } | safeContent"
            ></span>
          }
          <cg-options-selection
            #scSelection
            class="w-full [&>div>cg-icon]:top-4 [&>div>select]:!mt-0 [&>div>select]:min-h-14"
            [selectionId]="'sc-selection'"
            formControlName="sc"
            [placeholder]="'protectBooking.scPlaceholder' | transloco"
            [items]="scOptions()"
            [isPlaceholderDisabled]="false"
            (selectionChangedEmitter)="scChanged($event)"
          ></cg-options-selection>
        </div>
      </div>
    </div>

    <div class="mb-4 t:mb-8">
      <cg-headline
        class="mb-4 block"
        type="h3"
        [content]="'protectBooking.headlines.appointment' | transloco"
      ></cg-headline>

      <div class="flex flex-col gap-2 t:flex-row t:gap-4">
        <cg-date-input
          class="w-full self-end"
          formControlName="date"
          [minDate]="minDate"
          [maxDate]="maxDate"
          [filter]="filterDate"
        ></cg-date-input>
        <cg-options-selection
          #appointmentDate
          class="w-full [&>div>cg-icon]:top-4 [&>div>select]:!mt-0 [&>div>select]:min-h-14"
          [selectionId]="'appointment-id-selection'"
          formControlName="appointmentDate"
          [placeholder]="
            (noAppointmentsAvailable()
              ? 'protectBooking.appointmentPlaceholderNoAppointments'
              : 'protectBooking.appointmentPlaceholder'
            ) | transloco
          "
          [items]="appointmentOptions()"
          [isPlaceholderDisabled]="false"
        ></cg-options-selection>
      </div>
      @if (noAppointmentsAvailable()) {
        <cg-error-message [class]="'error-message'" [errorMessage]="'protectBooking.appointmentError' | transloco">
        </cg-error-message>
      }
    </div>

    <div class="mb-8 t:mb-12">
      <cg-headline class="mb-4 block" type="h3" [content]="'protectBooking.headlines.lpn' | transloco"></cg-headline>
      <cg-lpn-input></cg-lpn-input>
    </div>

    <cg-cta [content]="cta()" (clickedFn)="submit()"></cg-cta>
  </form>
</div>
