import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { ProtectBookingState } from "./protect-booking.reducer";
import { PROTECT_BOOKING_FEATURE_KEY } from "./protect-booking.reducer";

export const getProtectBookingState = createFeatureSelector<ProtectBookingState>(PROTECT_BOOKING_FEATURE_KEY);

export const getScs = createSelector(getProtectBookingState, (state: ProtectBookingState) =>
  state ? state.scs : null
);

export const getAppointments = createSelector(getProtectBookingState, (state: ProtectBookingState) =>
  state ? state.appointments : null
);

export const getBooked = createSelector(getProtectBookingState, (state: ProtectBookingState) =>
  state ? state.booked : null
);

export const getError = createSelector(getProtectBookingState, (state: ProtectBookingState) =>
  state ? state.error : null
);
