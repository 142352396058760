import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { tap } from "rxjs/operators";
import { GoogleTagManagerService } from "@cg/analytics";
import * as ProtectBookingActions from "./protect-booking.actions";

@Injectable()
export class TrackingEffects {
  private readonly actions$ = inject(Actions);
  private readonly gtmService = inject(GoogleTagManagerService);

  public bookSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtectBookingActions.bookSuccess),
        tap(() => {
          this.gtmService.trackEvent({
            eventCategory: "content",
            eventAction: "vaps-protect-only",
            eventLabel: "booking/success"
          });
        })
      ),
    { dispatch: false }
  );

  public bookFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtectBookingActions.bookFailure),
        tap(() => {
          this.gtmService.trackEvent({
            eventCategory: "content",
            eventAction: "vaps-protect-only",
            eventLabel: "booking/fail"
          });
        })
      ),
    { dispatch: false }
  );
}
