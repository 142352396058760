import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { warningIcon } from "@cg/icon";
import {
  BaseButtonComponent,
  BrandingComponent,
  CccOpeningHoursComponent,
  DialogComponent,
  DialogErrorComponent,
  FormatTelLinkPipe,
  OverlayService
} from "@cg/shared";

@Component({
  selector: "cg-protect-booking-error",
  standalone: true,
  imports: [
    DialogComponent,
    IconComponent,
    HeadlineComponent,
    CccOpeningHoursComponent,
    BrandingComponent,
    DialogErrorComponent,
    FormatTelLinkPipe,
    ParagraphComponent,
    TranslocoPipe,
    BaseButtonComponent
  ],
  templateUrl: "./protect-booking-error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProtectBookingErrorComponent {
  protected readonly overlayService = inject(OverlayService);

  protected readonly warningIcon = warningIcon;
  protected readonly headlineType = HeadlineType;

  public close(): void {
    this.overlayService.close();
  }
}
