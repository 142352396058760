import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent } from "@cg/core/ui";
import { CustomerratingMinimalComponent } from "@cg/customerrating";
import { BrandingComponent, CtaComponent, ResponsiveBgDirective } from "@cg/shared";
import type { StageHome } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-stage-home",
  templateUrl: "./stage-home.component.html",
  styleUrls: ["./stage-home.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    CustomerratingMinimalComponent,
    HeadlineComponent,
    CtaComponent,
    BrandingComponent,
    ResponsiveBgDirective
  ]
})
export class StageHomeComponent {
  private _content: StageHome;

  @Input()
  public set content(value: StageHome) {
    this._content = { ...value, headline: value.headline.replace(/<sup>®<\/sup>/g, "®") };
  }

  public get content(): StageHome {
    return this._content;
  }

  public constructor() {}
}
