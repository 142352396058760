import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "@cg/core/api";
import { ServiceCenterLocation } from "@cg/core/interfaces";
import { BookAppointmentRequest } from "../interfaces/book-appointment-request.interface";
import { GetAppointmentsRequest } from "../interfaces/get-appointments-request.interface";
import { GetAppointmentsResponse } from "../interfaces/get-appointments-response.interface";
import { GetServiceCenterRequest } from "../interfaces/get-service-center-request.interface";

@Injectable({
  providedIn: "root"
})
export class ProtectBookingService {
  private readonly apiService = inject(ApiService);

  public getScs({ lat, lng, formattedAddress }: GetServiceCenterRequest): Observable<ServiceCenterLocation[]> {
    return this.apiService.post("frontendProxy", "/protect-only/sc", {
      latitude: lat,
      longitude: lng,
      formattedAddress,
      radius: 40
    });
  }

  public getAppointments({ sc, date }: GetAppointmentsRequest): Observable<GetAppointmentsResponse[]> {
    return this.apiService.post("frontendProxy", "/protect-only/appointments", { sc, date });
  }

  public book({
    firstName,
    lastName,
    email,
    lpn,
    sc,
    appointmentDate,
    protectPrice
  }: BookAppointmentRequest): Observable<void> {
    return this.apiService.post("frontendProxy", "/protect-only/book", {
      firstName,
      lastName,
      email,
      lpn,
      sc,
      appointmentDate,
      protectPrice
    });
  }
}
