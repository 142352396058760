import type { TextInput } from "@cg/shared";

export const inputFields: {
  firstNameInput: TextInput;
  lastNameInput: TextInput;
  emailInput: TextInput;
  placeInput: TextInput;
} = {
  firstNameInput: {
    id: "firstname",
    controlName: "firstname",
    placeholder: "protectBooking.inputs.firstname.placeholder",
    errorMessage: "protectBooking.inputs.firstname.errorMessage"
  },
  lastNameInput: {
    id: "lastname",
    controlName: "lastname",
    placeholder: "protectBooking.inputs.lastname.placeholder",
    errorMessage: "protectBooking.inputs.lastname.errorMessage"
  },
  emailInput: {
    id: "email",
    controlName: "email",
    placeholder: "protectBooking.inputs.email.placeholder",
    errorMessage: "protectBooking.inputs.email.errorMessage"
  },
  placeInput: {
    id: "place",
    controlName: "place",
    placeholder: "protectBooking.inputs.place.placeholder",
    errorMessage: "protectBooking.inputs.place.errorMessage"
  }
};
