import { createReducer, on } from "@ngrx/store";
import type { GetAppointmentsResponse } from "../interfaces/get-appointments-response.interface";
import type { ServiceCenterLocation } from "@cg/core/interfaces";
import type { Action } from "@ngrx/store";
import * as ProtectBookingActions from "./protect-booking.actions";

export const PROTECT_BOOKING_FEATURE_KEY = "protectBooking";

export interface ProtectBookingState {
  scs: ServiceCenterLocation[];
  appointments: GetAppointmentsResponse[];
  booked: boolean;
  error?: string;
}

export interface ProtectBookingPartialState {
  readonly [PROTECT_BOOKING_FEATURE_KEY]: ProtectBookingState;
}

export const initialState: ProtectBookingState = {
  scs: [],
  appointments: [],
  booked: false,
  error: undefined
};

const protectBookingReducer = createReducer(
  initialState,
  on(
    ProtectBookingActions.getScsSuccess,
    (state: ProtectBookingState, action: { payload: ServiceCenterLocation[] }) => ({
      ...state,
      scs: action.payload
    })
  ),
  on(ProtectBookingActions.getScsFailure, (state: ProtectBookingState, action: { payload: string }) => ({
    ...state,
    error: action.payload
  })),

  on(
    ProtectBookingActions.getAppointmentsSuccess,
    (state: ProtectBookingState, action: { payload: GetAppointmentsResponse[] }) => ({
      ...state,
      appointments: action.payload
    })
  ),
  on(ProtectBookingActions.getAppointmentsFailure, (state: ProtectBookingState, action: { payload: string }) => ({
    ...state,
    error: action.payload
  })),

  on(ProtectBookingActions.bookSuccess, (state: ProtectBookingState) => ({
    ...state,
    booked: true
  })),
  on(ProtectBookingActions.bookFailure, (state: ProtectBookingState, action: { payload: string }) => ({
    ...state,
    error: action.payload
  }))
);

export function reducer(state: ProtectBookingState, action: Action): ProtectBookingState {
  return protectBookingReducer(state, action);
}
