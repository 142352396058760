import { inject, Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BookAppointmentRequest } from "../interfaces/book-appointment-request.interface";
import { GetAppointmentsRequest } from "../interfaces/get-appointments-request.interface";
import { GetServiceCenterRequest } from "../interfaces/get-service-center-request.interface";
import * as ProtectBookingActions from "./protect-booking.actions";
import * as ProtectBookingSelectors from "./protect-booking.selectors";

@Injectable({
  providedIn: "root"
})
export class ProtectBookingFacade {
  private readonly store = inject(Store);

  public scs$ = this.store.pipe(select(ProtectBookingSelectors.getScs));
  public appointments$ = this.store.pipe(select(ProtectBookingSelectors.getAppointments));
  public booked$ = this.store.pipe(select(ProtectBookingSelectors.getBooked));
  public error$ = this.store.pipe(select(ProtectBookingSelectors.getError));

  public getScs(payload: GetServiceCenterRequest): void {
    this.store.dispatch(ProtectBookingActions.getScs({ payload }));
  }

  public getAppointments(payload: GetAppointmentsRequest): void {
    this.store.dispatch(ProtectBookingActions.getAppointments({ payload }));
  }

  public book(payload: BookAppointmentRequest): void {
    this.store.dispatch(ProtectBookingActions.book({ payload }));
  }
}
