<div class="mx-auto mt-6 flex max-w-container flex-col t:mt-8 t:px-2 w:px-6 fhd:px-0">
  <div
    class="flex aspect-[12/5] w-full flex-col justify-end gap-y-0 rounded-3xl bg-cover bg-center bg-no-repeat px-4 t:gap-y-6 t:px-12 t:py-10 w:py-16"
    [ngClass]="{
      'max-h-[350px] min-h-[240px]': content?.cta,
      'max-h-[200px] min-h-[200px] py-6 t:max-h-[350px]': !content?.cta
    }"
    [cgResponsiveBg]="{ source: content?.picture.source, sizes: content?.picture.sizes }"
  >
    <cg-headline [content]="content?.headline"></cg-headline>
    @if (content?.cta) {
      <cg-cta class="translate-y-5 t:translate-y-0" [content]="content?.cta"></cg-cta>
    }
  </div>
  @if (content?.branding) {
    <cg-branding class="mt-10 block w-full t:mt-6 d:mt-8 [&>div]:mx-auto"></cg-branding>
  }
  @if (content?.trustpilot) {
    <cg-customerrating-minimal></cg-customerrating-minimal>
  }
</div>
