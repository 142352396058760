import { NgClass } from "@angular/common";
import { Component, computed, effect, inject, OnInit, signal, viewChild } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { map } from "rxjs";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { addDays, addHours, addWeeks, format } from "date-fns";
import { GoogleTagManagerService } from "@cg/analytics";
import { ConsentServiceKeys, UserCentricsService } from "@cg/consent-management";
import { Cta } from "@cg/content-api/typescript-interfaces";
import { ProtectConfig, ServiceCenterLocation } from "@cg/core/interfaces";
import { GoogleMapsService } from "@cg/core/services";
import { HeadlineComponent } from "@cg/core/ui";
import { arrowsIcon, doneIcon } from "@cg/icon";
import { LpnInputComponent } from "@cg/lpn-input";
import {
  ConfigFacade,
  CtaComponent,
  DateInputComponent,
  ErrorMessageComponent,
  GooglePlacesInputComponent,
  InputType,
  LocationAutocompleteResult,
  OptionsSelectionComponent,
  SafeContentPipe,
  TextInputComponent
} from "@cg/shared";
import { ProtectBookingFacade } from "../+state/protect-booking.facade";
import { BookAppointmentRequest } from "../interfaces/book-appointment-request.interface";
import { GetAppointmentsResponse } from "../interfaces/get-appointments-response.interface";
import { inputFields } from "./models/input-fields.model";

@Component({
  selector: "cg-protect-booking-form",
  standalone: true,
  imports: [
    HeadlineComponent,
    CtaComponent,
    LpnInputComponent,
    ReactiveFormsModule,
    TranslocoPipe,
    TextInputComponent,
    GooglePlacesInputComponent,
    OptionsSelectionComponent,
    DateInputComponent,
    SafeContentPipe,
    NgClass,
    ErrorMessageComponent
  ],
  templateUrl: "./protect-booking-form.component.html"
})
export class ProtectBookingFormComponent implements OnInit {
  private readonly userCentricsService = inject(UserCentricsService);
  private readonly protectBookingFacade = inject(ProtectBookingFacade);
  private readonly configFacade = inject(ConfigFacade);
  private readonly fb = inject(FormBuilder);
  private readonly translocoService = inject(TranslocoService);
  private readonly gtmService = inject(GoogleTagManagerService);

  public readonly inputFields = inputFields;
  public readonly InputType = InputType;
  public readonly doneIcon = doneIcon;
  public readonly minDate = addDays(new Date(), 1);
  public readonly maxDate = addWeeks(new Date(), 3);
  public readonly price = toSignal(
    this.configFacade.protectConfig$.pipe(map(({ price }: ProtectConfig) => price?.replace(".", ",")))
  );

  public appointmentDateViewChild = viewChild("appointmentDate", { read: OptionsSelectionComponent });
  public scSelectionViewChild = viewChild("scSelection", { read: OptionsSelectionComponent });

  public scs = toSignal(this.protectBookingFacade.scs$, { initialValue: [] });
  public appointments = toSignal(this.protectBookingFacade.appointments$, { initialValue: [] });

  public scOptions = computed(() =>
    this.scs()?.map((sc: ServiceCenterLocation) => ({
      id: sc.costCenter,
      value: sc.costCenter,
      text: sc.name,
      disabled: false
    }))
  );

  public appointmentOptions = computed(() =>
    this.appointments()
      ?.filter((appointment: GetAppointmentsResponse) => !!appointment?.appointmentDate)
      .map(({ appointmentDate }: GetAppointmentsResponse) => {
        const appDate = new Date(appointmentDate);
        const startTime = format(appDate, "HH:mm");
        const endTime = format(addHours(appDate, 1), "HH:mm");

        return {
          id: appointmentDate,
          value: appointmentDate,
          text: `${startTime} - ${endTime}`,
          disabled: false
        };
      })
  );

  public noAppointmentsAvailable = computed(
    () => !this.appointments().length && this.form.get("date").dirty && this.form.get("sc").dirty
  );

  public form: FormGroup = this.fb.group({
    firstName: ["", Validators.required],
    lastName: ["", Validators.required],
    email: ["", [Validators.required, Validators.email]],
    searchServiceCenterInput: ["", Validators.required],
    sc: ["", Validators.required],
    lpn: ["", Validators.required],
    date: ["", Validators.required],
    appointmentDate: ["", Validators.required]
  });

  public cta = toSignal(
    this.translocoService.selectTranslate("protectBooking.cta").pipe(
      map(
        (title: string): Cta => ({
          id: "submit",
          title,
          link: {
            href: "#",
            text: title
          },
          variation: "primary",
          arrowDirection: "right",
          icon: arrowsIcon,
          ngTemplate: "cgCta"
        })
      )
    )
  );

  private selectedSc = signal("");
  private selectedDate = toSignal(this.form.get("date").valueChanges);

  public constructor() {
    effect(
      () => {
        if (!this.selectedSc() || !this.selectedDate()) {
          return;
        }

        // hours are added to the date to get the correct day and not lost it due to timezone conversion from german to utc
        this.protectBookingFacade.getAppointments({
          sc: this.selectedSc(),
          date: addHours(this.selectedDate(), 6).toISOString()
        });
      },
      { allowSignalWrites: true }
    );

    effect(() => {
      this.appointmentDateViewChild().setDisabledState(!this.appointments().length);
    });

    effect(() => {
      this.scSelectionViewChild().setDisabledState(!this.scOptions().length);
    });
  }
  public ngOnInit(): void {
    this.userCentricsService.activateService<GoogleMapsService>(ConsentServiceKeys.googleMaps);
  }

  public handleAutocompleteResult({ address, lat, lng }: LocationAutocompleteResult) {
    this.protectBookingFacade.getScs({ lat, lng, formattedAddress: address });
  }

  public scChanged(val: string) {
    this.selectedSc.set(val);
  }

  public filterDate(date: Date | null): boolean {
    if (!date) {
      return false;
    }

    return date.getDay() !== 0;
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.trackFailure();
      this.trackInvalidForm();
      return;
    }

    const { firstName, lastName, email, sc, appointmentDate, lpn } = this.form.value;
    const payload: BookAppointmentRequest = {
      firstName,
      lastName,
      email,
      lpn: `${lpn.region}${lpn.letters ? "-" + lpn.letters : ""}${lpn.numbers ? "-" + lpn.numbers : ""}${lpn.additionalNumbers ? "-" + lpn.additionalNumbers : ""}`,
      sc,
      appointmentDate,
      protectPrice: this.price()?.replace(",", ".")
    };

    this.protectBookingFacade.book(payload);
  }

  private trackFailure(): void {
    this.gtmService.trackEvent({
      eventCategory: "content",
      eventAction: "vaps-protect-only",
      eventLabel: "booking/fail"
    });
  }

  private trackInvalidForm(): void {
    const invalidFormFields: string[] = [];
    for (const controlName in this.form.controls) {
      if (this.form.get(controlName).invalid) {
        invalidFormFields.push(controlName);
      }
    }

    for (const controlName of invalidFormFields) {
      const dashedControlName = controlName.replace(/([a-z\d])([A-Z])/g, "$1-$2").toLowerCase();
      this.gtmService.trackEvent({
        eventCategory: "content",
        eventAction: "error",
        eventLabel: dashedControlName
      });
    }
  }
}
