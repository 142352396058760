<div
  class="mx-auto mt-6 flex max-w-container flex-col items-center gap-6 t:mt-8 t:flex-row t:gap-8 t:px-2 d:gap-12 w:px-6 fhd:px-4 [@media(min-width:1280px)]:px-0"
>
  <div
    class="t:max-d:h-[245px] t:max-d:min-h-[245px] t:max-d:w-[245px] t:max-d:min-w-[245px] h-[168px] min-h-[168px] w-[168px] min-w-[168px] rounded-3xl bg-cover bg-center bg-no-repeat shadow-card d:h-[272px] d:min-h-[272px] d:w-[272px] d:min-w-[272px]"
    [cgResponsiveBg]="{ source: content?.picture.source, sizes: content?.picture.sizes }"
  ></div>

  <div>
    <cg-headline class="block [&>h1]:text-center [&>h1]:t:text-left" [content]="content?.headline"></cg-headline>

    <cg-branding class="block w-full pt-4 [&>div]:mx-auto [&>div]:t:ml-0 [&>div]:t:mr-auto"></cg-branding>

    @if (content?.trustpilot) {
      <cg-customerrating-minimal class="block t:-ml-4 t:w-fit d:-ml-6"></cg-customerrating-minimal>
    }
  </div>
</div>
