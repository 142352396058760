import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import { ProtectBookingEffects } from "./+state/protect-booking.effects";
import * as fromProtectBooking from "./+state/protect-booking.reducer";
import { TrackingEffects } from "./+state/tracking.effects";

export function provideProtectBooking() {
  return [
    provideState(fromProtectBooking.PROTECT_BOOKING_FEATURE_KEY, fromProtectBooking.reducer),
    provideEffects([ProtectBookingEffects, TrackingEffects])
  ];
}
