import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit } from "@angular/core";
import { KontoroFeatureFlagKeys, KontoroFeatureFlagService } from "@cg/analytics";
import { ResponsiveBgDirective } from "@cg/shared";
import type { StageSubpage } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-stage-sub",
  templateUrl: "./stage-sub.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ResponsiveBgDirective, NgClass]
})
export class StageSubComponent implements OnInit {
  private readonly kontoroService = inject(KontoroFeatureFlagService);
  private readonly cdr = inject(ChangeDetectorRef);

  @Input()
  public content: StageSubpage;

  public isHiddenByKontoro = false;

  public ngOnInit(): void {
    this.kontoroService
      .isFeatureFlagActive$(KontoroFeatureFlagKeys.HIDE_SUB_STAGE, "variant1")
      .subscribe((isHidden: boolean) => {
        this.isHiddenByKontoro = isHidden;
        this.cdr.markForCheck();
      });

    // Used to track the A/A test
    this.kontoroService
      .isFeatureFlagActive$(KontoroFeatureFlagKeys.A_A_TEST, "variant1")
      .subscribe((active: boolean) => {
        // eslint-disable-next-line no-console
        console.log(`A/A Test: ${active ? "VARIANT_1" : "CONTROL"}`);
      });
  }
}
