import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { HeadlineComponent } from "@cg/core/ui";
import { CustomerratingMinimalComponent } from "@cg/customerrating";
import { BrandingComponent, ResponsiveBgDirective } from "@cg/shared";
import type { StageSubpageAlternative } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-stage-sub-alternative",
  templateUrl: "./stage-sub-alternative.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeadlineComponent, BrandingComponent, ResponsiveBgDirective, CustomerratingMinimalComponent]
})
export class StageSubAlternativeComponent {
  @Input()
  public content: StageSubpageAlternative;
}
